<template>
    <MainLayout v-bind:showNavbar="true" v-bind:showFooter="true">        
        <header id="head" class="secondary"></header>

        <!-- container -->
        <div class="container">

            <ol class="breadcrumb">
                <li><router-link to="/">Home</router-link></li>
                <li class="active">Suporte</li>
            </ol>

            <div class="row">

                <!-- Article main content -->
                <!-- <form id="frmSuporte">  -->
                    <article class="col-sm-9 maincontent">
                        <header class="page-header">
                            <h1 class="page-title">Suporte</h1>
                        </header>

                        <p>
                            Preencha os dados em seguida clique em enviar.
                        </p>
                        <br />
                        <div class="row">
                            <div class="col-sm-3">
                                <input id="txtCNPJ" name="txtCNPJ" class="form-control" type="text" placeholder="CNPJ" />
                            </div>
                            <div class="col-sm-3">
                                <input id="txtContato" name="txtContato" class="form-control ucase" type="text" placeholder="Nome de contato" />
                            </div>
                            <div class="col-sm-3">
                                <input id="txtTelefone" name="txtTelefone" class="form-control" type="text" placeholder="Telefone" />
                            </div>
                            <div class="col-sm-3">
                                <select class="form-control" id="cboPrioridade">
                                    <option value="-1">Prioridade</option>
                                    <option value="0">Baixo</option>
                                    <option value="1">Médio</option>
                                    <option value="2">Alto</option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-12">
                                <textarea id="txtDetalhes" name="txtDetalhes" placeholder="Escreva detalhes aqui..." class="form-control" rows="9"></textarea>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <input id="btnEnvSolSuporte" class="btn btn-action" type="submit" value="Enviar" />
                            </div>
                        </div>

                    </article>                    
                <!-- </form> -->
                 <!-- /Article  -->
                 <!-- Sidebar -->
                <aside class="col-sm-3 sidebar sidebar-right">

                    <div class="widget">
                        <h4>E-mails</h4>
                        suporte@setbyte.com.br
                        <br />comercial@setbyte.com.br
                        <br />financeiro@setbyte.com.br
                        <br /><br />
                        <h4>Telefone:</h4>
                        <address>(83) 98139-8906 </address>
                    </div>

                </aside>
                 <!-- /Sidebar -->

            </div>
        </div>	
        <!-- /container -->

        <section class="container-full top-space">
            <div id="map"></div>
        </section>        

    </MainLayout>
</template>

<script>    
    import MainLayout from '../layouts/Main.vue'

    export default {
        components: {
            MainLayout
        },                
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
        },
        mounted() {
            // Carregar scripts específicos para o componente no head.
            // const plugin = document.createElement("script");
            // plugin.setAttribute(
            // "src",
            // "//api.myplugincom/widget/mykey.js"
            // );
            // plugin.async = true;
            // document.head.appendChild(plugin);
        }
    }

</script>