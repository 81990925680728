<template>
    <MainLayout v-bind:showNavbar="true" v-bind:showFooter="true">
        <header id="head" class="secondary"></header>
        <!-- container -->
        <div class="container">
            <ol class="breadcrumb">
                <li>
                    <router-link to="/">Home</router-link>                
                </li>
                <li class="active">Sobre</li>
            </ol>
            <div class="row">
                <!-- Article main content -->
                <article class="col-sm-8 maincontent">
                    <header class="page-header">
                        <h1 class="page-title">Sobre</h1>
                    </header>
                    <h3>setbyte</h3>
                    <p>
                        <img src="assets/images/mac.jpg" alt="" class="img-rounded pull-right" width="300">Com sede em Campina Grande, PB a setbyte vem se destacando no segmento de TI, pela qualidade de seus produtos e serviços que atende varios nichos de empresas.
                    </p>
                    <p>Com foco em soluções cada vez mais sofisticadas, a setbyte converte tecnologias em soluções para garantir que sua empresa cresça e consiga fazer mais em menos tempo.</p>
                    <h3>Missão</h3>
                    <p><span style="color: rgb(77, 77, 79); font-family: Arial; font-size: 12px; font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: 17.1429px; orphans: auto; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 1; word-spacing: 0px; -webkit-text-stroke-width: 0px; display: inline !important; float: none; background-color: rgb(255, 255, 255);">Oferecer soluções de qualidade em todos os segmentos do varejo por meio de um serviço de atendimento eficaz e eficiente. Buscamos estabelecer, construir e desenvolver relacionamentos duradouros e fiéis com cada um de nossos clientes e parceiros, priorizando sempre a satisfação total de cada um destes com a setbyte.</span></p>

                    <h3>Valores</h3>
                    <span style="color: rgb(77, 77, 79); font-family: Arial; font-size: 12px; font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; line-height: 17.1429px; orphans: auto; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 1; word-spacing: 0px; -webkit-text-stroke-width: 0px; display: inline !important; float: none; background-color: rgb(255, 255, 255);">
                        <ul>
                            <li>Cooperação;</li>
                            <li>Transparência;</li>
                            <li>Responsabilidade;</li>
                            <li>Comprometimento e Confiança;</li>
                            <li>Igualdade e Respeito;</li>
                            <li>Criatividade e Ousadia;</li>
                            <li>Parceria e Satisfação.</li>
                        </ul>
                    </span>



                </article>
                <!-- /Article -->
                <!-- Sidebar -->
                <!--<aside class="col-sm-4 sidebar sidebar-right">
                    <div class="widget">
                        <h4>Vacancies</h4>
                        <ul class="list-unstyled list-spaces">
                            <li>
                                <a href="">Lorem ipsum dolor</a>
                                <br>
                                <span class="small text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, laborum.</span>
                            </li>
                            <li>
                                <a href="">Totam, libero, quis</a>
                                <br>
                                <span class="small text-muted">Suscipit veniam debitis sed ipsam quia magnam eveniet perferendis nisi.</span>
                            </li>
                            <li>
                                <a href="">Enim, sequi dignissimos</a>
                                <br>
                                <span class="small text-muted">Reprehenderit illum quod unde quo vero ab inventore alias veritatis.</span>
                            </li>
                            <li>
                                <a href="">Suscipit, consequatur, aut</a>
                                <br>
                                <span class="small text-muted">Sed, mollitia earum debitis est itaque esse reiciendis amet cupiditate.</span>
                            </li>
                            <li>
                                <a href="">Nam, illo, veritatis</a>
                                <br>
                                <span class="small text-muted">
                                    Delectus, sapiente illo provident quo aliquam nihil beatae dignissimos
                                    itaque.
                                </span>
                            </li>
                        </ul>
                    </div>
                </aside> -->
                <!-- /Sidebar -->
            </div>
        </div>
        <!-- /container -->
    </MainLayout>
</template>

<script>    
    import MainLayout from '../layouts/Main.vue'

    export default {
        components: {
            MainLayout
        },                
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
        }
    }

</script>