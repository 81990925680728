<template>
    <header id="head">
        <div class="container">
            <div class="row">
                <h1 class="lead">Temos a solução ideal para sua empresa</h1>
                <p class="tagline">
                    <!-- <img src="assets\images\documentos_fiscais.png"> -->
                </p>
                <p></p>
                <p>
                    <a class="btn btn-default btn-lg" role="button">Mais informações</a>
                    <a class="btn btn-action btn-lg" role="button" asp-page="/Suporte">Solicitar SUPORTE</a>
                </p>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>