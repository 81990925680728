<template>
    <footer id="footer" class="top-space">
        <div class="footer1">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 widget">
                        <h3 class="widget-title">Contato</h3>
                        <div class="widget-body">
                            <p>

                                (83) 98139-8906
                            </p>
                            suporte@setbyte.com.br
                            <br />comercial@setbyte.com.br
                            <br />financeiro@setbyte.com.br
                            <br />
                            <br />x &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <br />
                            <br />Campina Grande, PB
                            <p></p>
                        </div>
                    </div>
                    <div class="col-md-3 widget">
                        <h3 class="widget-title">Siga-nos</h3>
                        <div class="widget-body">
                            <p class="follow-me-icons">
                                <a href="#"><i class="fa fa-twitter fa-2"></i></a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 widget">
                        <h3 class="widget-title">Nossa missão</h3>
                        <div class="widget-body">
                            <p>
                                A setbyte vem se destacando no segmento de TI por prover aos seus parceiros soluções com padrões internacionais de desenvolvimento de software, a nossa
                                missão é converter as tecnologias existentes em soluções cada vez mais
                                sofisticadas para que a sua empresa possa produzir mais em menos tempo
                                e ter capacidade para suportar a demanda de informações em todos os setores
                                da empresa.
                            </p>
                            <p></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 widget">
                        <div class="widget-body">
                            <p class="simplenav">

                                <router-link to="/">Home</router-link>|
                                <router-link to="/sobre">Sobre</router-link>|
                                <!-- <a href="sidebar-right.html">Produtos</a>| -->
                                <router-link to="/suporte">Suporte</router-link>|
                                <!-- <router-link to="/blog">Blog</router-link>| -->
                                <router-link to="/contato">Contatos</router-link>|
                                <b style="display: none;">
                                    <router-link to="javascript:;">Logar&nbsp;</router-link>
                                </b>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 widget">
                        <div class="widget-body">
                            <p class="text-right">Copyright © 2021, setbyte.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>

import aLink from './aLink.vue'

export default {
  name: 'Footer',
  components: {
      aLink
  }
}
</script>