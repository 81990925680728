<template>    
        <div class="navbar navbar-inverse navbar-fixed-top headroom">
            <div class="container">

                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <router-link class="navbar-brand" to="/"><img src="assets/images/logo.png" alt="Ir para página inicial" /></router-link>
                </div>
                <div class="navbar-collapse collapse">
                    <ul class="nav navbar-nav pull-right">

                        <aLink to="/">Home</aLink>
                        <aLink to="/sobre">Sobre</aLink>
                        <aLink to="/suporte">Suporte</aLink>
                        <aLink to="/contato">Contato</aLink>
                        <aLink to="/blog" style="display: none;">Blog</aLink>                                               
                        <aLink to="javascript:;" class="btn" style="display: none;">Logar / Cadastrar</aLink>
                    </ul>
                </div>
                <!-- /.nav-collapse -->
            </div>
        </div>    
</template>

<script>

import aLink from './aLink.vue'

export default {
  name: 'Navbar',
  components: {
    aLink
  },
  mounted() {

    //# efeito do navbar
    var myElement = document.getElementsByClassName('headroom')[0];        
    var header = new Headroom(myElement, {
            "tolerance": 20,
            "offset": 50,
            "classes": {
                "initial": "animated",
                "pinned": "slideDown",
                "unpinned": "slideUp"
            }
    });
    header.init();
  }
}
</script>