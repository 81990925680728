<template>      
    <MainLayout v-bind:showNavbar="true" v-bind:showHeader="true" v-bind:showFooter="true">
        <!-- Intro -->
        <div class="container text-center">
            <br>
            <br>
            <h2 class="thin">Está cansado de perder informações importantes do movimento da sua empresa devido a falha no sistema?</h2>
            <p class="text-muted">
                Os nossos produtos contam com o Set-Drive, um serviço de backup inteligente
                que garante a fidelidade das informações do seu sistema na Nuvem tudo isso
                com a qualidade que você ja conhece... <a href="#">Saiba Mais</a>
                <br>
                <br>
            </p>
        </div>
        <!-- /Intro -->

        <!-- Highlights - jumbotron -->
        <div class="jumbotron top-space">
            <div class="container">
                <h3 class="text-center thin">Porque contratar os nossos serviços?</h3>
                <div class="row">
                    <div class="col-md-3 col-sm-6 highlight">
                        <div class="h-caption">
                            <h4>
                                <i class="fa fa-cogs fa-5"></i>Desenvolvimendo
                            </h4>
                        </div>
                        <div class="h-body text-center">
                            <p>
                                Os serviços e produtos que disponibilizamos são elaborados seguindo padrões
                                internacionais de desenvolvimento de software o que garante para sua empresa
                                um produto diferenciado.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 highlight">
                        <div class="h-caption">
                            <h4>
                                <i class="fa fa-flash fa-5"></i>Faça mais em menos tempo
                            </h4>
                        </div>
                        <div class="h-body text-center">
                            <p>Com soluções 100% integradas, nosso sistema oferece a sua empresa grandes facilidades onde seus colaboradores podem realizar multiplas tarefas em um reduzido espaço de tempo comparado aos sistemas convencionais.</p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 highlight">
                        <div class="h-caption">
                            <h4>
                                <i class="fa fa-heart fa-5"></i>Colaboração
                            </h4>
                        </div>
                        <div class="h-body text-center">
                            <p>Oferecendo serviços de qualidade, investimos na motivação de nossos colaboradores, visando a satisfação da sua empresa por trabalhar com pessoas que amam o que fazem.</p>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 highlight">
                        <div class="h-caption">
                            <h4>
                                <i class="fa fa-smile-o fa-5"></i>Suporte líder
                            </h4>
                        </div>
                        <div class="h-body text-center">
                            <p>Contamos com um serviço de suporte flexível e preparado, para dar a assistência necessária em todo período de funcionamento da sua empresa.</p>
                        </div>
                    </div>
                </div>
                <!-- /row -->
            </div>
        </div>

        <!-- container -->
        <div class="container">
            <h2 class="text-center top-space">Perguntas frequentes</h2>
            <br>
            <div class="row">
                <div class="col-sm-6">
                    <h3>O que a setbyte faz?</h3>
                    <p>A Setbyte é uma empresa de desenvolvimento de softwares e serviços de TI, trabalhamos com o intuito de trazer soluções inovadoras que auxiliam o funcionamento e rendimento da sua empresa.</p>
                </div>
                <div class="col-sm-6">
                    <h3>O que é NFS-e?</h3>
                    <p>
                        A Nota Fiscal de Serviços Eletrônica (NFS-e) é um documento de existência
                        digital, gerado e armazenado eletronicamente em Ambiente Nacional pela
                        RFB, pela prefeitura ou por outra entidade conveniada, para documentar
                        as operações de prestação de serviços.
                    </p>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-6">
                    <h3>O que é NFC-e?</h3>
                    <p>
                        A NFC-e é um documento eletrônico (arquivo de computador) que substituirá
                        as notas fiscais de venda a consumidor, modelo 2 e o cupom fiscal emitido
                        por ECF com grandes vantagens para as empresas, para o Fisco e para a sociedade.
                    </p>
                </div>
                <div class="col-sm-6">
                    <h3>Onde a setbyte atua?</h3>
                    <p>A setbyte atua em todos os estados que aderiram ao projeto da NFC-e, com um produto inovador focado no crescimento da sua empresa.</p>
                </div>
            </div>    
            <div class="jumbotron top-space">
                <h4>Prezado cliente, leia nossa politica de privacidade e não deixe de seguir as recomendações enviadas pelo nosso departamento de segurança, que trabalha sempre para mantê-lo informado sobre os riscos do uso indevido da internet.</h4>
                <p class="text-right">
                    <a class="btn btn-primary btn-large">Ler mais...</a>
                </p>
            </div>
        </div>

        <section id="social">
            <div class="container">
                <div class="wrapper clearfix">            
                    <div class="addthis_toolbox addthis_default_style">
                        <a class="addthis_button_tweet"></a>
                    </div>          
                </div>
            </div>
        </section>


    </MainLayout>
</template>


<script>    
    import MainLayout from '../layouts/Main.vue'

    export default {
        components: {
            MainLayout
        },                
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
        }
    }

</script>