<template>
    <MainLayout v-bind:showNavbar="true" v-bind:showFooter="true">        
          <header id="head" class="secondary"></header>

        <!-- container  -->
        <div class="container">

            <ol class="breadcrumb">
                <li><router-link to="/">Home</router-link></li>
                <li class="active">Contato</li>
            </ol>

            <div class="row">

                <!-- Article main content  -->
                <article class="col-sm-9 maincontent">
                    <header class="page-header">
                        <h1 class="page-title">Contato</h1>
                    </header>

                    <p>
                        Preencha os dados em seguida clique em enviar.
                    </p>
                    <br />
                    <form>
                        <!-- @Html.AntiForgeryToken() -->
                        <div class="row">
                            <div class="col-sm-4">
                                <input id="txtNome" name="txtNome" class="form-control ucase" type="text" placeholder="Nome" />
                            </div>
                            <div class="col-sm-4">
                                <input id="txtEmail" name="txtEmail" class="form-control" type="text" placeholder="Email" />
                            </div>
                            <div class="col-sm-4">
                                <input id="txtTelefone" name="txtTelefone" class="form-control" type="text" placeholder="Telefone" />
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-12">
                                <textarea id="txtMensagem" name="txtMensagem" placeholder="Escreva sua mensagem aqui..." class="form-control" rows="9"></textarea>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="checkbox"><input id="chkRecEmails" name="chkRecEmails" type="checkbox" /> Inscrever-me para receber e-mails com notícias entre outros assuntos.</label>
                            </div>
                            <div class="col-sm-6 text-right">
                                <input id="btnEnviarContato" class="btn btn-action" type="submit" value="Enviar" />
                            </div>
                        </div>
                    </form>
                </article>
                <!-- /Article  -->
                <!-- Sidebar  -->
                <aside class="col-sm-3 sidebar sidebar-right">

                    <div class="widget">
                        <h4>E-mails</h4>
                        suporte@setbyte.com.br
                        <br>comercial@setbyte.com.br
                        <br>financeiro@setbyte.com.br
                        <br /><br />
                        <h4>Telefone:</h4>
                        <address>                    
                            (83) 98139-8906
                        </address>
                    </div>

                </aside>
                <!-- /Sidebar -->

            </div>
        </div>	
        <!-- /container -->

        <section class="container-full top-space">
            <div id="map"></div>
        </section>


    </MainLayout>
</template>

<script>    
    import MainLayout from '../layouts/Main.vue'

    export default {
        components: {
            MainLayout
        },                
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
        }
    }

</script>