<template>    
    <router-link v-bind:to="to" custom v-slot="{ navigate, isActive, href }">
        <li :class="[isActive && 'active', isExactActive && '']" >
            <a :href="href" @click="navigate" :class="$attrs.class" :style="$attrs.style" ><slot></slot></a>
        </li>
    </router-link>    
</template>

<script>
    export default {
        name: 'Footer',
        props: {
            to: ''                  
        }
    }
</script>
