<template>
  <Navbar v-if='showNavbar'/>
  <Header v-if='showHeader' />
  <slot></slot>
  <Footer v-if='showFooter' />
</template>

<script>
  import Navbar from '../components/Navbar.vue'
  import Header from '../components/Header.vue'
  import Footer from '../components/Footer.vue'

  export default {
    components: {
      Navbar,
      Header,
      Footer
    },
    props: {
      showNavbar: false,
      showHeader: false,
      showFooter: false
    }
  }
</script>